import React, { useState } from "react";
import MainLayout from "../layouts/Main";
import "../template-styles/our-vision.scss";
import Divider from "../Components/General/Divider/Divider";
import Button from "../Components/Button/Button";
import Arrow from "../Components/Arrow/Arrow";
import Banner from "../Components/Header/Banner";
import { graphql } from "gatsby";
import { Carousel } from "react-responsive-carousel";

const responsiveCarousel = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 2561 },
    items: 5,
    slidesToSlide: 5,
  },
  desktop: {
    breakpoint: { max: 2560, min: 1166 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1165, min: 769 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const renderPrevButton = (clickHandler, hasPrev, label) => {
  return (
    hasPrev && (
      <div className={"blocker-prevButton"} onClick={clickHandler}>
        <img alt="" className="img" src="/images/arrow.svg" />
      </div>
    )
  );
};
const renderNextButton = (clickHandler, hasNext, label) => {
  return (
    hasNext && (
      <div className={"blocker-nextButton"} onClick={clickHandler}>
        <img alt="" className="img" src="/images/arrow.svg" />
      </div>
    )
  );
};

const sanitizeDescription = description => {
  // Remove <p>, </p>, <ul>, </ul>, <li>, </li>, <strong>, </strong>, <br />, and replace apostrophe entities
  return description
    .replace(/<p>/g, "")
    .replace(/<\/p>/g, "")
    .replace(/<ul>/g, "")
    .replace(/<\/ul>/g, "")
    .replace(/<li>/g, "")
    .replace(/<\/li>/g, "")
    .replace(/<strong>/g, "")
    .replace(/<\/strong>/g, "")
    .replace(/&#8217;/g, "'");
};

function OurVision({ data, props }) {
  const sections =
    data?.wordPress?.hPageTemplates?.nodes[0]?.hPages?.nodes[0]?.studioFields
      ?.ourVision;

  function getSEOForPage(data) {
    return data?.wordPress?.hPageTemplates?.nodes[0].hPages.nodes[0];
  }

  const pageSEO = getSEOForPage(data);

  const [flippedCards, setFlippedCards] = useState({});

  const handleCardFlip = (cardIndex, sectionId) => {
    setFlippedCards(prevState => ({
      ...prevState,
      [sectionId]: {
        ...prevState[sectionId],
        [cardIndex]: !prevState[sectionId]?.[cardIndex], // Toggle the specific card
      },
    }));
  };

  return (
    <MainLayout
      lang={"us"}
      languages={["us", "uk"]}
      className={"mask5"}
      defaultStickyMenu={true}
      {...props}
      seoMeta={pageSEO}
    >
      <Banner top="117px" />{" "}
      {sections.map((section, index) => {
        const type = section?.__typename;
        switch (type) {
          case "WordPress_HPage_Studiofields_OurVision_ImageAndText":
            return (
              <>
                <div
                  style={{
                    position: "relative",
                    backgroundColor: section.wrapperBackgroundColor,
                  }}
                >
                  <div className="header-image-container">
                    <div className="header-container">
                      <div
                        dangerouslySetInnerHTML={{ __html: section?.header }}
                      />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: section?.description || "",
                        }}
                      />
                    </div>
                    <img
                      className="image-styles"
                      src={section?.image?.sourceUrl || ""}
                      alt=""
                    />
                  </div>
                  {/* <div className="bg-mask-test-pattern">
                <img src='/images/platform/Pattern-05.svg' className='header-image-background' />
              </div> */}
                  {/* <div className="header-arrow-container">
              <Arrow className="header-arrow" dark />
              </div> */}
                </div>
              </>
            );
          case "WordPress_HPage_Studiofields_OurVision_LongDescriptionSection":
            return (
              <>
                <div
                  style={{
                    position: "relative",
                    padding: "2rem",
                    backgroundColor:
                      section?.wrapperBackgroundColor || "#191919",
                  }}
                >
                  <div className="long-description-container">
                    <div style={{ color: section?.borderColor }} />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: section?.description || "",
                      }}
                    />
                    <div style={{ color: section?.borderColor }} />
                  </div>
                </div>
              </>
            );
          case "WordPress_HPage_Studiofields_OurVision_Divider":
            return (
              <Divider
                additionalClass={section.additionalclass}
                key={"section-" + index}
                bgColor={section.backgroundColor}
                maskColor={section.maskColor}
                direction={
                  section.position.toLowerCase() +
                  "-" +
                  section.orientation.toLowerCase()
                }
              />
            );
          case "WordPress_HPage_Studiofields_OurVision_SkewedDivider":
            return (
              <div style={{ position: "relative" }}>
                <div
                  className="skewed-divider"
                  style={{ backgroundColor: section.backgroundColor }}
                />
              </div>
            );
          case "WordPress_HPage_Studiofields_OurVision_FlipCardSection":
            return (
              <div
                id={section?.sectionId}
                style={{
                  backgroundColor: section?.wrapperBackgroundColor,
                  position: "relative",
                }}
              >
                <div className="flip-card-desc-container">
                  <h2 style={{ color: section?.textColor || "#ffffff" }}>
                    {section?.mainHeader}
                  </h2>
                  <h4 style={{ color: section?.textColor || "#ffffff" }}>
                    {section?.secondaryHeader}
                  </h4>
                  <p>{section?.mainDescription}</p>
                  <div className="flip-card-container">
                    {section?.cardFields?.map((card, index) => {
                      return (
                        <div
                          onClick={() =>
                            handleCardFlip(index, section.sectionId)
                          }
                          className={`our-vision-card ${
                            flippedCards[section.sectionId]?.[index]
                              ? "is-flipped"
                              : ""
                          }`}
                          style={{
                            backgroundColor: flippedCards[section.sectionId]?.[
                              index
                            ]
                              ? section?.cardFields[0]?.cardBackBackgroundColor
                              : section?.cardFields[0]
                                  ?.cardFrontBackgroundColor,
                          }}
                          key={index}
                        >
                          <img
                            className={`our-vision-card-icon`}
                            src="/images/swap-hover-icon-black.svg"
                            alt="Hover icon"
                          />
                          <img
                            className={
                              !flippedCards[section.sectionId]?.[index]
                                ? "our-vision-card-image"
                                : "none"
                            }
                            src={
                              card?.cardImage?.sourceUrl &&
                              card?.cardImage.sourceUrl
                            }
                            alt="Card Image"
                          />
                          <p
                            style={{
                              color: flippedCards[section.sectionId]?.[index]
                                ? section?.cardFields[0]?.cardBackTextColor
                                : section?.cardFields[0]?.cardFrontTextColor,
                              fontWeight: flippedCards[section.sectionId]?.[
                                index
                              ]
                                ? 400
                                : 700,
                            }}
                            className={
                              flippedCards[section.sectionId]?.[index]
                                ? "is-flipped"
                                : ""
                            }
                            dangerouslySetInnerHTML={{
                              __html: flippedCards[section.sectionId]?.[index]
                                ? sanitizeDescription(card?.cardBackDescription)
                                : sanitizeDescription(
                                    card?.cardFrontDescription
                                  ),
                            }}
                          ></p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );

          case "WordPress_HPage_Studiofields_OurVision_VideoSection":
            return (
              <div
                style={{
                  position: "relative",
                  backgroundColor: section?.wrapperBackgroundColor,
                }}
              >
                <div className="our-vision-video-section-container">
                  <h2>{section?.mainHeader}</h2>
                  <h4>{section?.secondaryHeader}</h4>
                  <p>{section?.mainDescription}</p>
                  {section?.video && (
                    <div className="iframe-container">
                      <iframe
                        src={section?.video}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded Video"
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          case "WordPress_HPage_Studiofields_OurVision_PlatformApplicationSection":
            return (
              <>
                <section
                  className="our-vision-platform-section"
                  style={{
                    backgroundColor:
                      section.wrapperBackgroundColor || "#f5ebe0",
                  }}
                >
                  <div className="our-vision-platform-container">
                    <h2>
                      {section.mainHeader ||
                        "Applications of the Wunderkind Platform"}
                    </h2>
                    <h4>
                      {section.subHeader ||
                        "Streamline the entire marketing build, report, and optimization process."}
                    </h4>
                    {section.imageAndTextCta &&
                      section.imageAndTextCta.map((ctaItem, index) => (
                        <div
                          key={index}
                          className={`our-vision-platform-image-text-container ${
                            ctaItem.leftOrRight === "Right"
                              ? "flex-row-reverse"
                              : ""
                          }`}
                        >
                          <div
                            className={`our-vision-platform-header-desc-btn-container`}
                          >
                            <h3>{ctaItem.header || "Studio"}</h3>
                            <p>
                              {ctaItem.description ||
                                "Studio streamlines the production stage of design to increase the agility with which brands create best-in-class creatives and experiences."}
                            </p>
                            {ctaItem.cta && (
                              <a href={`${ctaItem.ctaHref}`}>
                                <Button className="btn btn-dark">
                                  {ctaItem.cta || "Learn More"}
                                </Button>
                              </a>
                            )}
                          </div>
                          {ctaItem.image && ctaItem.image.sourceUrl && (
                            <div
                              className={`our-vision-platform-image-container`}
                            >
                              <img
                                src={ctaItem.image.sourceUrl}
                                alt={
                                  ctaItem.header || "Default Image Description"
                                }
                              />
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </section>
              </>
            );

          case "WordPress_HPage_Studiofields_OurVision_KeyCapabilitiesSection":
            return (
              <section
                style={{
                  position: "relative",
                  backgroundColor: section?.wrapperBackgroundColor,
                }}
              >
                <div className="key-capabilities-container">
                  <div className="key-capabilities-header-image-desc-container">
                    <div className="key-capabilities-image-container">
                      <img src={section?.image?.sourceUrl} alt="" />
                    </div>
                    <div className="key-capabilities-header-desc-container">
                      <h3 className="key-capabilities-header">
                        {section?.keyCapabilitiesHeader}
                      </h3>
                      {section?.keyCapabilitiesFields?.map(
                        (keyCapabilityField, index) => (
                          <div
                            key={index}
                            className="key-capabilities-logo-header-desc-container"
                          >
                            {keyCapabilityField?.logo?.sourceUrl && (
                              <img
                                src={keyCapabilityField.logo.sourceUrl}
                                alt=""
                              />
                            )}
                            <div>
                              <h3>{keyCapabilityField?.header}</h3>
                              <p>{keyCapabilityField?.description}</p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </section>
            );

          case "WordPress_HPage_Studiofields_OurVision_HeaderImageSection":
            return (
              <section className="our-vision-coming-next-section">
                <div className="our-vision-coming-next-container">
                  <div className="our-vision-coming-next-header-btn-image-container">
                    <div className="our-vision-coming-next-header-btn-container">
                      <h2>
                        {section?.mainHeader ||
                          "Wunder what's coming next at Wunderkind?"}
                      </h2>
                      <Button href={section?.ctaHref} className="bg-3d55cc">
                        {section?.cta || "Stay Tuned"}
                      </Button>
                    </div>
                    <div className="our-vision-coming-next-image-container">
                      <img
                        src={section?.image.sourceUrl}
                        alt="Artist With Innovator Characters"
                      />
                    </div>
                  </div>
                </div>
              </section>
            );

          case "WordPress_HPage_Studiofields_OurVision_IdentityMultipleLongDescriptions":
            return (
              <div
                style={{
                  backgroundColor: section?.wrapperBackgroundColor,
                  padding: "2rem",
                }}
              >
                <div className="our-vision-identity-carousel-outer-container">
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    renderArrowPrev={renderPrevButton}
                    renderArrowNext={renderNextButton}
                  >
                    {section?.multipleQuotes?.map((quoteItem, index) => (
                      <div
                        key={index}
                        className="our-vision-identity-long-desc-container"
                      >
                        <div
                          style={{ backgroundColor: quoteItem?.borderColor }}
                        />
                        <p className="our-vision-identity-long-desc">
                          {quoteItem?.quote}
                        </p>
                        <p className="our-vision-identity-long-desc-client-name">
                          {quoteItem?.clientNameAndPosition}
                        </p>
                        <div
                          style={{ backgroundColor: quoteItem?.borderColor }}
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            );

          case "WordPress_HPage_Studiofields_OurVision_IdentityImageAndTextSection":
            return (
              <div className="our-vision-identity-image-and-text-wrapper">
                <div className="our-vision-identity-background-pattern-wrapper">
                  <img
                    className="our-vision-identity-background-pattern"
                    src={section?.background?.sourceUrl}
                    alt=""
                  />
                </div>
                <div className={"our-vision-identity-bg-mask"}>
                  <img src={"/images/how-it-works/background-blue-mask2.svg"} />
                </div>
                <div className="our-vision-identity-image-and-text-container">
                  <div className="our-vision-identity-header-image-container">
                    <div className="our-vision-identity-header-desc-container">
                      <h2>{section?.header}</h2>
                      <p>{section?.description}</p>
                    </div>
                    <div className="our-vision-identity-image-container">
                      <img src={section?.image.sourceUrl} />
                    </div>
                  </div>
                </div>
              </div>
            );
          case "WordPress_HPage_Studiofields_OurVision_IdentityFlipCardsSection":
            return (
              <div
                className="our-vision-identity-flip-card-wrapper"
                id={section?.sectionId}
                style={{ backgroundColor: section?.wrapperBackgroundColor }}
              >
                <div className="flip-card-desc-container">
                  <h6>{section?.smallHeader}</h6>
                  <h4 className="flip-card-main-header">
                    {section?.mainHeader}
                  </h4>
                  <div className="identity-flip-card-container">
                    {section?.cardData?.map((card, index) => {
                      return (
                        <div
                          onClick={() =>
                            handleCardFlip(index, section.sectionId)
                          }
                          className={`our-vision-identity-flip-card ${
                            flippedCards[section.sectionId]?.[index]
                              ? "is-flipped bg-f5ebe0"
                              : ""
                          }`}
                          key={index}
                        >
                          <img
                            className="our-vision-card-icon"
                            src="/images/swap-hover-icon-black.svg"
                            alt=""
                          />
                          {/* Icon Image */}
                          <img
                            src={card?.frontIcon?.sourceUrl}
                            alt="icon"
                            className={`${
                              flippedCards[section.sectionId]?.[index]
                                ? `none`
                                : `flip-card-icon`
                            }`}
                          />
                          {/* Front or Back Header and Description */}
                          <div
                            className={`${
                              flippedCards[section.sectionId]?.[index]
                                ? `flip-card-front-header is-flipped bold`
                                : `flip-card-front-header`
                            }`}
                          >
                            {card?.frontHeader}
                          </div>
                          {flippedCards[section.sectionId]?.[index] && (
                            <>
                              <p className="is-flipped">
                                {card?.backDescription}
                              </p>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );

          case "WordPress_HPage_Studiofields_OurVision_IdentityKeyCapabilitiesSection":
            return (
              <div className="identity-key-capabilities-wrapper">
                <div className="our-vision-identity-key-capabilities-background-pattern">
                  <img
                    className="our-vision-identity-key-capabilities-background-pattern"
                    src="/images/our-vision/background-pattern-beige.svg"
                  />
                </div>
                {/* <div className={"our-vision-identity-bg-mask"}>
                  <img src={"/images/how-it-works/background-blue-mask2.svg"} />
                </div> */}
                <div className="identity-key-capabilities-container">
                  <h6>{section?.header}</h6>
                  <div className="identity-key-capabilities-grid">
                    {section?.keyCapabilitiesFields.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="identity-key-capabilities-logo-header-container"
                        >
                          <div className="identity-key-capabilities-header-desc-container">
                            <img
                              src={
                                item &&
                                item?.logo &&
                                item?.logo?.sourceUrl &&
                                item.logo.sourceUrl
                              }
                              alt=""
                            />
                            <div>
                              <h3>{item.header}</h3>
                              <p>{item.description}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );

          case "WordPress_HPage_Studiofields_OurVision_IdentityLeaderInIdentitySection":
            return (
              <div
                style={{
                  backgroundColor: section?.wrapperColor,
                  color: section?.textColor,
                }}
                className="identity-leader-in-resolution-wrapper"
              >
                <div className="identity-leader-in-resolution-header-image-container">
                  <div className="identity-leader-in-resolution-header-cta-container">
                    <h2>{sanitizeDescription(section?.header)}</h2>
                    <p>{sanitizeDescription(section?.description)}</p>
                    {section?.ctaHref && (
                      <Button
                        className="identity-leader-in-resolution-btn"
                        href={section?.ctaHref}
                      >
                        {section?.ctaText}
                      </Button>
                    )}
                  </div>
                  <div className="identity-leader-in-resolution-image-container">
                    <img src={section?.image.sourceUrl} alt="" />
                  </div>
                </div>
              </div>
            );
          case "WordPress_HPage_Studiofields_OurVision_PersonaRevenuePowerhouseSection":
            return (
              <div
                style={{
                  backgroundColor: section?.wrapperBackgroundColor,
                  color: section?.textColor,
                }}
                className="persona-powerhouse-wrapper"
              >
                <h6>{section?.smallHeader}</h6>
                <h2>{section?.largeHeader}</h2>
                <div className="persona-powerhouse-desc-container">
                  {section?.repeatingDescriptions?.map((item, index) => {
                    return <p key={index}>{item.description}</p>;
                  })}
                </div>
              </div>
            );

          case "WordPress_HPage_Studiofields_OurVision_ResultsNotComplexitySection":
            return (
              <div
                style={{
                  backgroundColor: section?.wrapperBackgroundColor,
                  color: section?.textColor,
                }}
                className="persona-results-not-complexity-wrapper"
              >
                <div className="persona-results-not-complexity-header-container">
                  <div>
                    <h2>{section?.largeHeader}</h2>
                    <p>{section?.description}</p>
                  </div>
                  <div>
                    <img src={section?.image?.sourceUrl} alt="" />
                  </div>
                </div>
              </div>
            );

          default:
            return <></>;
        }
      })}
    </MainLayout>
  );
}

export const query = graphql`
  query OurVisionPageQuery($title: String = "title") {
    wordPress {
      hPageTemplates(where: { slug: "our-vision" }) {
        nodes {
          hPages(where: { title: $title }) {
            nodes {
              title
              slug
              studioFields {
                ourVision {
                  ... on WordPress_HPage_Studiofields_OurVision_ImageAndText {
                    cta
                    description
                    header
                    wrapperBackgroundColor
                    image {
                      sourceUrl
                    }
                    imageBackground {
                      sourceUrl
                    }
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_LongDescriptionSection {
                    borderColor
                    description
                    wrapperBackgroundColor
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_Divider {
                    backgroundColor
                    fieldGroupName
                    maskColor
                    orientation
                    position
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_SkewedDivider {
                    backgroundColor
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_FlipCardSection {
                    fieldGroupName
                    mainDescription
                    sectionId
                    cardFields {
                      cardFrontBackgroundColor
                      cardFrontTextColor
                      cardBackBackgroundColor
                      cardBackTextColor
                      cardFrontDescription
                      cardBackDescription
                      cardImage {
                        sourceUrl
                      }
                    }
                    mainHeader
                    secondaryHeader
                    wrapperBackgroundColor
                    textColor
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_VideoSection {
                    fieldGroupName
                    mainDescription
                    mainHeader
                    secondaryHeader
                    video
                    wrapperBackgroundColor
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_PlatformApplicationSection {
                    fieldGroupName
                    mainHeader
                    subHeader
                    imageAndTextCta {
                      cta
                      ctaHref
                      description
                      header
                      image {
                        sourceUrl
                      }
                      imageBackground {
                        sourceUrl
                      }
                      leftOrRight
                    }
                    wrapperBackgroundColor
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_KeyCapabilitiesSection {
                    wrapperBackgroundColor
                    image {
                      sourceUrl
                    }
                    keyCapabilitiesHeader
                    keyCapabilitiesFields {
                      logo {
                        sourceUrl
                      }
                      header
                      description
                    }
                    backgroundPattern {
                      sourceUrl
                    }
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_HeaderImageSection {
                    cta
                    ctaHref
                    fieldGroupName
                    image {
                      sourceUrl
                    }
                    mainHeader
                    wrapperBackgroundColor
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_IdentityLongDescriptionSection {
                    clientNameAndPosition
                    longDescription
                    wrapperBackgroundColor
                    borderColor
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_IdentityMultipleLongDescriptions {
                    wrapperBackgroundColor
                    multipleQuotes {
                      quote
                      clientNameAndPosition
                      borderColor
                    }
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_IdentityFlipCardsSection {
                    mainHeader
                    sectionid
                    cardData {
                      backDescription
                      backHeader
                      frontHeader
                      frontIcon {
                        sourceUrl
                      }
                    }
                    smallHeader
                    wrapperBackgroundColor
                    textColor
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_IdentityKeyCapabilitiesSection {
                    header
                    keyCapabilitiesFields {
                      header
                      description
                      logo {
                        sourceUrl
                      }
                    }
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_IdentityImageAndTextSection {
                    description
                    background {
                      sourceUrl
                    }
                    header
                    image {
                      sourceUrl
                    }
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_IdentityLeaderInIdentitySection {
                    ctaHref
                    ctaText
                    header
                    image {
                      sourceUrl
                    }
                    textColor
                    wrapperColor
                    description
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_PersonaRevenuePowerhouseSection {
                    largeHeader
                    smallHeader
                    textColor
                    wrapperBackgroundColor
                    repeatingDescriptions {
                      description
                    }
                  }
                  ... on WordPress_HPage_Studiofields_OurVision_ResultsNotComplexitySection {
                    wrapperBackgroundColor
                    largeHeader
                    textColor
                    description
                    image {
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default OurVision;
